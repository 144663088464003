import { useEffect, useState } from "react";
import GuestLayout from "@/Layouts/GuestLayout";
import InputError from "@/Components/InputError";
import InputLabel from "@/Components/InputLabel";
import TextInput from "@/Components/TextInput";
import PrimaryButton from "@/Components/PrimaryButton";
import { Head, Link, useForm, usePage } from "@inertiajs/react";
import LoginHeader from "@/Components/LoginComponents/LoginHeader";
import LoginFooter from "@/Components/LoginComponents/LoginFooter";
import LoginCarousel from "@/Components/LoginComponents/LoginCarousel";
import { Checkbox } from "@/Components/Ui/Checkbox.jsx";
import Success from "@/Components/Notices/Success.jsx";
import Lang from "lang.js";
import { EyeNoneIcon } from "@radix-ui/react-icons";
import { EyeOpenIcon } from "@radix-ui/react-icons";

var lang = new Lang();
var applicationMessages = await fetch("/lang.dist.js")
    .then((response) => response.json())
    .then((json) => {
        return json;
    });
lang.setMessages(applicationMessages);
export default function Login({ status, canResetPassword }) {
    const { flash } = usePage().props;
    const [showPassword, setShowPassword] = useState(false);

    const { data, setData, post, processing, errors, reset } = useForm({
        email: "",
        password: "",
        remember: false,
    });

    useEffect(() => {
        return () => {
            reset("password");
        };
    }, []);

    const submit = (e) => {
        e.preventDefault();

        post(route("login"));
    };

    return (
        <GuestLayout>
            <Head title="Log in" />

            <section className="login_page_grid">
                <div className="login_page_grid_slider">
                    <LoginCarousel />
                </div>
                <div className="login_page_grid_form">
                    <LoginHeader />
                    <div className="login_page_grid_content">
                        <div className="login_page_grid_content_title">
                            <h2>{lang.get("rido.welcome.title")}</h2>
                            <p>{lang.get("rido.welcome.description")}</p>
                        </div>

                        {Object.keys(errors).length > 0 && (
                            <>
                                <div className="notification error">
                                    {errors[Object.keys(errors)[0]]}
                                </div>
                            </>
                        )}

                        {flash.message && (
                            <Success message={flash.message}> </Success>
                        )}

                        <form onSubmit={submit}>
                            <div className="form_group">
                                <InputLabel htmlFor="email" value="Username" />

                                <TextInput
                                    id="email"
                                    type="email"
                                    name="email"
                                    value={data.email}
                                    autoComplete="username"
                                    placeholder="Enter your email or username"
                                    isFocused={true}
                                    onChange={(e) =>
                                        setData("email", e.target.value)
                                    }
                                />
                            </div>

                            <div className="form_group">
                                <InputLabel
                                    htmlFor="password"
                                    value="Password"
                                />

                                <TextInput
                                    id="password"
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    value={data.password}
                                    placeholder="Enter your password"
                                    autoComplete="current-password"
                                    onChange={(e) =>
                                        setData("password", e.target.value)
                                    }
                                />
                                <span
                                    className="password-toggle-icon"
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                >
                                    {showPassword ? (
                                        <EyeOpenIcon />
                                    ) : (
                                        <EyeNoneIcon />
                                    )}
                                </span>
                            </div>

                            <div className="form_group login_utility">
                                <div className="login_utility_left">
                                    <Checkbox id="terms" />
                                    <label
                                        htmlFor="terms"
                                        className="login_utility_left_label"
                                    >
                                        {lang.get(
                                            "rido.general.keep_logged_in"
                                        )}
                                    </label>
                                </div>
                                <div className="login_utility_right">
                                    {canResetPassword && (
                                        <Link href={route("password.request")}>
                                            {lang.get(
                                                "passwords.forgot_your_password"
                                            )}
                                        </Link>
                                    )}
                                </div>
                            </div>

                            <div>
                                <PrimaryButton
                                    className="submit_btn"
                                    disabled={processing}
                                >
                                    {lang.get("rido.general.login")}
                                </PrimaryButton>
                            </div>
                        </form>
                    </div>
                    <LoginFooter />
                </div>
            </section>
        </GuestLayout>
    );
}
